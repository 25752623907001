.banner-container {
    padding: 20px;
    margin: auto;
    margin-top: 30px;
    max-width: 1200px;
    min-height: 200px;
    box-sizing: border-box; /* Ensure padding and margins don’t affect layout */
}

.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #8da6b2;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Prevent overflow */
    z-index: 2;
    position: relative;
}

.title {
    color: #333;
    font-size: 2em;
    margin-right: 20px;
    opacity: 1;
    transition: opacity 1s ease, transform 1s ease; /* Smooth transition */
    transform: translateY(0); /* Reset translation */
}

.logos-container {
    display: flex;
    gap: 10px;
    opacity: 1;
    transition: opacity 1s ease, transform 1s ease; /* Smooth transition */
    transform: translateY(0); /* Reset translation */
}

.logo {
    width: 50px;  /* Set a reasonable size for the logos */
    height: 50px; /* Logos will be 40x40 pixels */
    object-fit: contain; /* Ensure logos maintain aspect ratio */
    background-color: transparent;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2)); /* Add a drop shadow for aesthetics */
}

/* Responsive logo sizes for smaller screens */
@media (max-width: 768px) {
    .title {
        font-size: 1.5em;
    }

    .logo {
        width: 30px;
        height: 30px; /* Smaller logos for smaller screens */
    }
}

.title,
.logos-container {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.title[style*="opacity: 0"],
.logos-container[style*="opacity: 0"] {
    visibility: hidden; /* Ensure the space remains occupied */
}
