  /* Projects.css */
#project-title {
  padding: 50px auto;
  font-size: clamp(2rem, 4vw + 1rem, 3rem);
}
  .projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 20px;
    padding: 20px;
    text-align: center;
    flex-direction: column;
    align-content: center;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }

/* Individual project cards */
.project-card {
  background-color: #eee6e1; /* Card background color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 30px 50px; /* Padding inside the card */
  margin: 10px; /* Margin around each card */
  flex: 1 1 280px; /* Allow flexibility in sizing */
  max-width: 1600px; /* Set maximum width for the card */
  min-width: 250px; /* Ensure a minimum width for smaller screens */
  text-align: center; /* Center content inside the card */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .projects-container {
      flex-direction: column; /* Stack cards vertically on small screens */
      align-items: center; /* Center cards horizontally */
  }

  .project-card {
      max-width: 90%; /* Allow cards to take up more width on small screens */
      margin: 10px 0; /* Margin between cards when stacked */
  }
}


  .project-card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .project-card p {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 20px;
  }

  .project-card a {
    font-size: 1.1em;
    color: #3861c3;
    text-decoration: none;
  }

  .project-card a:hover {
    text-decoration: underline;
  }

