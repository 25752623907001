@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global CSS Reset */
/*or change to darker color? old color was C8D9E6*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a, button {
  outline: none; /* Remove outline from all links and buttons */
  text-decoration: none; /* Remove underline from links */
  cursor: pointer; /* Change cursor to pointer for better UX */
}

/* Ensure the full height for html, body, and root */
html, body, #root {
  height: 100%;
}

body, html {
  margin: 0;
  padding: 0;
  background-color: #C8D9E6; /* This allows the particles to be visible */
}

.content {
  position: relative; /* Allow stacking context for absolute children */
  z-index: 1; /* Ensure content is above the canvas */
  padding: 20px;
}

/* Using EB Garamond for headings */
h1, h2, h3 {
  font-family: 'EB Garamond', sans-serif;
}

/* Using Lora for body text */
body, p {
  font-family: 'Lora', serif;
}




/* Basic Navbar styling */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #567C8D;
    position: relative; /* Ensure navbar itself is positioned */
    z-index: 2; /* Ensure navbar is above other sections */
}

.navbar h1 {
    color: #FFFFFF;
    font-size: auto;
    font-style: bold;
}

/* Navbar links container */
.navbar-links {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    grid-gap: 2px;
    gap: 2px; /* Space between navbar links */
}

/* Hide the links by default on mobile screens */
.navbar-links.open {
    display: block; /* Show links when hamburger is open */
}

/* Navbar link styles */
.navbar-links a {
    text-decoration: none;
    font-family: 'Lora', serif;
    color: #FFFFFF;
    font-size: 18px;
    padding: 10px 20px; /* Add horizontal and vertical padding */
    display: block; /* Ensure each link takes full width */
    margin: 5px 0; /* Add vertical margin between items */
    outline: none; /* Remove outline */
}

/* Remove outline on focus */
.navbar-links a:focus {
    outline: none; /* Prevent outline on focus */
}

/* Navbar link hover styles */
.navbar-links a:hover {
    font-size: larger; /* Change text color on hover */
    background: transparent; /* Ensure no background change */
}

/* Hamburger menu styles for mobile */
.navbar-hamburger {
    display: none; /* Hide the hamburger icon on larger screens */
    cursor: pointer; /* Change cursor to pointer for better UX */
}

.hamburger-icon {
    font-size: 28px; /* Size of the hamburger icon */
}

/* Media Query: Show the hamburger on small screens and hide the links */
@media (max-width: 768px) {
    .navbar-links {
        display: none; /* Hide the navbar links by default on small screens */
        flex-direction: column; /* Stack links vertically */
        width: 100%;
        text-align: center;
        background-color: #567C8D; /* Background for the dropdown */
        position: absolute; /* Position the dropdown */
        top: 60px; /* Adjust position as needed */
        left: 0;
        z-index: 999; /* Set higher z-index to ensure it's above other sections */
    }

    .navbar-links.open {
        display: block; /* Show the links when in open state */
    }

    .navbar-hamburger {
        display: block; /* Show the hamburger icon on small screens */
    }

    .navbar-links li {
        margin: 15px 0; /* Space out items vertically */
    }

    .navbar-logo h1 {
        font-size: 24px; /* Adjust logo size for mobile */
    }
}

.about-section-container {
    position: relative; /* Ensure particles are positioned within this container */
    width: 100%;
    background-color: transparent;
}


.about-section {
    position: relative;
    z-index: 1; /* Ensure the content is on top of particles */
    padding: 40px 20px;
    text-align: center;
    margin: 0 auto;
    max-width: 1100px;
    background-color: transparent;
}

.headshot {
    border-radius: 50%;
    width: 230px;
    height: 230px;
    margin: 10px;
    object-fit: cover;
}


.typewriter {
    font-family: 'Lora', serif;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    font-size: 2rem;
    border-right: 2px solid;
}

.blinking::after {
    content: '|';
    position: absolute;
    right: 0;
    animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

.typewriter:not(.blinking) {
    border-right: none;
}

/* Default font size for larger screens */
.about-section p {
    line-height: 1.3;
    font-size: 1.3rem;
    color: #333;
}

/* Adjust font size for smaller screens */
@media (max-width: 1600px) {
    .about-section p {
        font-size: 1.2rem; /* Smaller font size for tablets or smaller screens */
    }
}
@media (max-width: 678px) {
    .about-section p {
        font-size: 1rem; /* Smaller font size for tablets or smaller screens */
    }
}

@media (max-width: 480px) {
    .about-section p {
        font-size: 0.8rem; /* Even smaller font size for mobile */
    }
}

.banner-container {
    padding: 20px;
    margin: auto;
    margin-top: 30px;
    max-width: 1200px;
    min-height: 200px;
    box-sizing: border-box; /* Ensure padding and margins don’t affect layout */
}

.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #8da6b2;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Prevent overflow */
    z-index: 2;
    position: relative;
}

.title {
    color: #333;
    font-size: 2em;
    margin-right: 20px;
    opacity: 1;
    transition: opacity 1s ease, transform 1s ease; /* Smooth transition */
    transform: translateY(0); /* Reset translation */
}

.logos-container {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    opacity: 1;
    transition: opacity 1s ease, transform 1s ease; /* Smooth transition */
    transform: translateY(0); /* Reset translation */
}

.logo {
    width: 50px;  /* Set a reasonable size for the logos */
    height: 50px; /* Logos will be 40x40 pixels */
    object-fit: contain; /* Ensure logos maintain aspect ratio */
    background-color: transparent;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2)); /* Add a drop shadow for aesthetics */
}

/* Responsive logo sizes for smaller screens */
@media (max-width: 768px) {
    .title {
        font-size: 1.5em;
    }

    .logo {
        width: 30px;
        height: 30px; /* Smaller logos for smaller screens */
    }
}

.title,
.logos-container {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.title[style*="opacity: 0"],
.logos-container[style*="opacity: 0"] {
    visibility: hidden; /* Ensure the space remains occupied */
}

  /* Projects.css */
#project-title {
  padding: 50px auto;
  font-size: clamp(2rem, 4vw + 1rem, 3rem);
}
  .projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    margin: 20px;
    padding: 20px;
    text-align: center;
    flex-direction: column;
    align-content: center;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }

/* Individual project cards */
.project-card {
  background-color: #eee6e1; /* Card background color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 30px 50px; /* Padding inside the card */
  margin: 10px; /* Margin around each card */
  flex: 1 1 280px; /* Allow flexibility in sizing */
  max-width: 1600px; /* Set maximum width for the card */
  min-width: 250px; /* Ensure a minimum width for smaller screens */
  text-align: center; /* Center content inside the card */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .projects-container {
      flex-direction: column; /* Stack cards vertically on small screens */
      align-items: center; /* Center cards horizontally */
  }

  .project-card {
      max-width: 90%; /* Allow cards to take up more width on small screens */
      margin: 10px 0; /* Margin between cards when stacked */
  }
}


  .project-card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .project-card p {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 20px;
  }

  .project-card a {
    font-size: 1.1em;
    color: #3861c3;
    text-decoration: none;
  }

  .project-card a:hover {
    text-decoration: underline;
  }


/* ProjectDetails.css */

/* General Layout */
.project-details {
  margin: 30px 50px 30px 50px;
  color: #333;
}

.project-details h1,
.project-details p {
  text-align: center;
  margin: auto;
}

.project-details h1 {
  font-size: 2.5em;
  color: #1e2a47;
  margin-bottom: 10px;
  text-align: center;
}

.project-details p,
.project-details ul {
  font-size: 1.1em;
  line-height: 1.6;
}

.project-details .details,
.project-details .details2 {
  margin: auto;
  text-align: left;
}

.project-details .details li,
.project-details .details2 li {
  list-style-type: square;
  margin-bottom: 10px;
}

/* Image Section */
.project-images {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap; /* Allow images to wrap if needed */
  justify-content: center; /* Center the images horizontally */
  grid-gap: 15px;
  gap: 15px; /* Space between images */
}

.project-images .image-container {
  width: calc(60% - 30px); /* Adjust width as needed */
  text-align: center;
  margin: 15px;
}

.project-images img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.project-images .image-description {
  font-size: 0.9em;
  color: #555;
  margin-top: 5px;
  align-items: center;
}


/* PDF Viewer */
.project-pdf {
  margin-top: 40px;
}

.project-pdf h2 {
  font-size: 1.8em;
  color: #1e2a47;
}

.project-pdf iframe {
  border-radius: 8px;
}

/* Video Section */
.project-video {
  margin-top: 40px;
}

.project-video h2 {
  font-size: 1.8em;
  color: #1e2a47;
}

.project-video video {
  border-radius: 8px;
  max-width: 100%;
}

/* Text Styling */
.details {
  margin-left: 30px;
  font-size: 1.1em;
  color: #444;
}

.details2 {
  margin-top: 30px;
  font-size: 1.1em;
  color: #444;
}

.project-details br {
  margin-bottom: 30px;
}

.project-pdf, .project-video {
  margin: 20px; /* Adjust as needed */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: adds a slight background color */
  padding: 10px; /* Optional: adds padding for better appearance */
  border-radius: 8px; /* Optional: rounds the corners */
}

.pdf, .vid {
  border-radius: 8px;
}

.project-details a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.project-details a:hover {
  text-decoration: underline;
}

.details p {
  margin-bottom: 1.5em; /* Adjust spacing as needed */
  text-align: left;

}


/* Resume.css */
section {
    text-align: center;
    margin-bottom: 10px;;
}

.resume-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center the buttons */
}

.resume-button {
    display: inline-block; /* Treat buttons like inline blocks */
    text-decoration: none; /* Remove text underline */
    color: white; /* Text color */
    background-color: #007BFF; /* Button background color */
    padding: 10px; /* Padding around text */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    font-size: 1rem; /* Font size */
    margin: 2px 0; /* Space between buttons */
    min-width: 150px; /* Ensure both buttons have a minimum width */
    text-align: center; /* Center the text */
}

.resume-button:hover {
    background-color: #0056b3; /* Darker background on hover */
}


/* Footer.css */

footer {
    padding: 40px 20px; /* Add some padding */
    text-align: center; /* Center align text */
    background: linear-gradient(90deg, rgba(98,144,198,1) 0%, rgba(21,132,161,1) 36%, rgba(178,197,212,1) 86%);
    position: relative; /* Ensure proper positioning for child elements */
    margin: auto;
}

/* New container for contact cards */
.contact-cards-container {
    display: flex; /* Use flexbox to align items side by side */
    justify-content: space-around; /* Space the contact cards evenly */
    flex-wrap: wrap; /* Allow the cards to wrap on smaller screens */
}

.contact-card {
    background-color: #eee6e1; /* Card background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px; /* Padding inside the card */
    margin: 10px; /* Margin around each card */
    max-width: 800px; /* Set maximum width */
    flex: 1 1; /* Allow cards to grow and shrink based on available space */
    min-width: 300px; /* Ensure a minimum width for responsiveness */
    min-height: 200px;
    text-align: center; /* Center content inside the card */
}

@media (max-width: 768px) {
    .contact-cards-container {
        flex-direction: column; /* Stack the cards vertically on small screens */
    }

    .contact-card {
        max-width: 100%; /* Make the cards full-width on small screens */
        margin: 10px auto; /* Center them vertically */
    }
}



.contact-card form {
    display: flex;
    flex-direction: column; /* Stack inputs vertically */
}

.message-label {
    font-family: 'Lora', serif; /* Apply Lora font to the message label */
    font-size: 16px; /* Optional: Adjust font size */
    margin-bottom: 5px; /* Space between label and textarea */
}

.contact-card input,
.contact-card textarea {
    margin: 10px 0; /* Space between inputs */
    padding: 10px; /* Padding inside inputs */
    border: 1px solid #ccc; /* Border for inputs */
    border-radius: 4px; /* Rounded corners */
    width: 100%; /* Full width */
}

.contact-card button {
    text-decoration: none;
    color: white; /* Text color for the buttons */
    background-color: #007BFF; /* Button background color */
    padding: 8px 16px; /* Reduced padding for less bulk */
    border-radius: 4px; /* Rounded corners */
    margin: 5px 2px; /* Reduced space between buttons */
    display: inline-block; /* Ensure they sit side by side */
    border: none;
}

.contact-card button:hover {
    background-color: #0056b3; /* Darker button color on hover */
}

.socials {
    margin: 20px 0; /* Space around social links */
}

.socials a {
    margin: 0 10px; /* Space between icons */
    font-size: 24px; /* Icon size */
    color: #333; /* Icon color */
    transition: color 0.3s; /* Smooth transition on hover */
}

.socials a:hover {
    color: #F5EFEB; /* Change color on hover */
}

.copyright {
    margin-top: 20px; /* Space above copyright */
    font-size: 16px; /* Font size for copyright */
    color: #333; /* Color for copyright text */
}

.contact-card .material-symbols-outlined {
    font-size: 20px;
}

.certification-links {
    list-style: none; /* Remove bullet points */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

.certification-links li {
    margin: 5px 0; /* Space between list items, adjust as needed */
}

.certification-links a {
    text-decoration: none; /* Remove underline from links */
    color: #007BFF; /* Link color */
    transition: color 0.3s; /* Smooth color transition on hover */
    display: block; /* Make links fill the list item */
    padding: 5px; /* Add padding for clickable area */
    border-radius: 4px; /* Optional: rounded corners for the links */
}

.certification-links a:hover {
    color: #0056b3; /* Darker shade on hover */
    background-color: rgba(0, 123, 255, 0.1); /* Optional: background color on hover */
}

#certifications p {
    font-size: large;
}


.contact-card p {
    font-size: large;
}

.response-message {
    font-size: 1.2rem; /* Adjust size as needed */
    color: green; /* Change to your desired color */
    margin-top: 10px; /* Add some spacing if needed */
}
